import React, { useState } from 'react'
import 'swiper/css'
import { Autoplay } from 'swiper'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'

const OurClient = () => {
  const [client] = useState([1, 2, 3, 4, 5, 6, 7, 8])
  const [partner] = useState([1, 2, 3, 4, 5, 6, 7, 8])
  return (
    <div className='bg-white py-[26px] lg:py-[56px] px-4 lg:px-14'>
      <div className='mb-[36px]'>
        <h1 className='text-center text-[#2E7462] text-2xl md:text-[36px] font-semibold'>
          Our Clients and Partners
        </h1>
      </div>
      <Swiper
        slidesPerView={3}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false
        }}
        speed={1000}
        breakpoints={{
          320: {
            slidesPerView: 3
          },
          768: {
            slidesPerView: 4
          },
          1024: {
            slidesPerView: 5
          }
        }}
        modules={[Autoplay]}
      >
        <>
          <SwiperSlide>
            <div className='flex h-24 items-center justify-center'>
              <span className='text-lg md:text-2xl text-primary'>Clients</span>
            </div>
          </SwiperSlide>
          {client.map(item => (
            <SwiperSlide key={item}>
              <div className='flex h-24 items-center justify-center'>
                <img
                  className='w-full h-full object-contain'
                  src={`/images/client/${item}.png`}
                  alt='client'
                />
              </div>
            </SwiperSlide>
          ))}
          <SwiperSlide>
            <div className='flex h-24 items-center justify-center border-l border-[#2E7462]'>
              <span className='text-lg md:text-2xl text-primary'>Partners</span>
            </div>
          </SwiperSlide>
          {partner.map(item => (
            <SwiperSlide key={item}>
              <div className='flex h-24 items-center justify-center'>
                <img
                  className='w-full h-full object-contain'
                  src={`/images/partner/${item}.png`}
                  alt='partner'
                />
              </div>
            </SwiperSlide>
          ))}
        </>
      </Swiper>
    </div>
  )
}

export default OurClient

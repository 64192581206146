import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import Sidebar from 'src/components/molecules/Navigation/Sidebar'
import SearchForm from 'src/components/molecules/SearchForm'
const Product = () => {
    const stateMenu = useSelector(state => state.menu.value)
    const ClassSidebarWrapper = classNames({
        "fixed top-0 bottom-0 z-40 md:translate-x-0 md:relative w-full md:w-[247px] bg-white border-r": true,
        "transition-all duration-500": true,
        "-translate-x-0": stateMenu,
        "-translate-x-full": !stateMenu,
    })
    return (
        <div className='flex bg-[#F3F3F3]'>
            <div className={ClassSidebarWrapper}>
                <div className="sticky md:pt-20 top-[112px]">
                    <Sidebar />
                </div>
            </div>
            <div className='flex-1 bg-white'>
                <SearchForm />
                <Outlet />
            </div>
        </div>
    )
}

export default Product
import axios from 'axios'
import { useState } from 'react'
import { BsEye } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import CardLoader from 'src/components/orgainsms/CardProduct/CardLoader'

import useSwr from 'swr'

const fether = async url => {
  const response = await axios(url)
  return response.data
}

const MainGallery = () => {
  const navigate = useNavigate()
  const API = process.env.REACT_APP_API
  const { data, isLoading } = useSwr(API + '/gallery', fether)
  const handleDetail = data => {
    navigate(`/gallery/${data.id}`, {
      state: data
    })
  }
  return (
    <>
      <div className='h-[50vh] md:h-[80vh] object-cover relative'>
        <img
          className='w-full h-full object-cover'
          src='/images/bg-gallery.png'
          alt='gallery'
        />
        <div className='absolute flex flex-col text-white left-0 right-0 top-0 bottom-0'>
          <div className='w-full flex-1 flex flex-col justify-center items-center max-w-5xl mx-auto'>
            <h1 className='text-center text-5xl my-4'>
              Bringing Your Vision to Life
            </h1>
            <h2 className='text-center text-2xl my-4'>Our Project Portfolio</h2>
          </div>
          <div className='h-20 text-center text-[10px] sm:text-[14px] lg:text-[16px] flex items-center justify-center'>
            VIDEOTRON - VIDEOWALL - CCTV - PROCESSOR - INSTALLATION
          </div>
        </div>
      </div>
      <div className='px-4 lg:px-14 py-14'>
        <div className='grid gap-[24px] sm:grid-cols-2 md:grid-cols-3'>
          {isLoading && <CardLoader />}
          {data?.map(item => (
            <GalleryItem
              title={item.title}
              year={item.tgl}
              key={item.id}
              image={item.image}
              onClick={() => handleDetail(item)}
            />
          ))}
        </div>
      </div>
    </>
  )
}

const GalleryItem = ({ title, year, onClick, image }) => {
  const thumb =
    image.length > 0
      ? process.env.REACT_APP_IMAGE + '/' + image[0].image
      : '/images/gallery/1.png'
  return (
    <button onClick={onClick} className='group block hover:shadow-xl'>
      <div className='h-[200px] md:h-[200px] lg:h-[277px] relative'>
        <div className='absolute opacity-0 group-hover:opacity-100 duration-300 left-4 right-4 top-4 bottom-4 flex justify-end items-end'>
          <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center'>
            <BsEye className='text-2xl text-[#2E7462]' />
          </div>
        </div>
        <img
          className='w-full h-full group-hover:rounded-none object-cover'
          src={thumb}
          alt='gallery'
        />
      </div>
      <div className='p-4 text-start group-hover:bg-[#2E7462] transition-all duration-300'>
        <h3 className='text-[#2E7462] group-hover:text-white text-[22px] font-[500]'>
          {title}
        </h3>
        <span className='text-gray-500 text-[12px] group-hover:text-white'>
          {year}
        </span>
      </div>
    </button>
  )
}

export default MainGallery

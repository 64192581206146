import React from 'react'

const About = () => {
    return (
        <div className='h-[70vh] flex items-center justify-center'>
            <div className="text-center text-4xl my-5">About here...</div>
        </div>
    )
}

export default About
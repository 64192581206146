
import Aos from "aos";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import DefaultLayout from "./components/layouts/DefaultLayout";
import About from "./pages/About";
import Article from "./pages/Article";
import ArticleDetail from "./pages/Article/ArticleDetail";
import ArticleFilter from "./pages/Article/ArticleFilter";
import ArticleList from "./pages/Article/ArticleList";
import ArticleSearch from "./pages/Article/ArticleSearch";
import Gallery from "./pages/Gallery";
import DetailGallery from "./pages/Gallery/DetailGallery";
import MainGallery from "./pages/Gallery/MainGallery";
import Landing from "./pages/Landing";
import Product from "./pages/Product";
import PerCategory from "./pages/Product/PerCategory";
import ProductList from "./pages/Product/ProductList";
import ProductSearch from "./pages/Product/ProductSearch";
import Service from "./pages/Service";
import ServiceList from "./pages/Service/ServiceList";
function App() {
  useEffect(() => {
    Aos.init();
  }, [])

  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);

  return (
    <Routes>
      <Route path="/gallery/:slug" element={<DetailGallery />} />
      <Route path="/" element={<DefaultLayout />}>
        <Route index element={<Landing />} />
        <Route path="about" element={<About />} />
        <Route path="/gallery" element={<Gallery />}>
          <Route index element={<MainGallery />} />
        </Route>
        <Route path="/articles" element={<Article />}>
          <Route index element={<ArticleList />} />
          <Route path="category/:slug" element={<ArticleFilter />} />
          <Route path="search/:slug" element={<ArticleSearch />} />
          <Route path=":slug" element={<ArticleDetail />} />
        </Route>
        <Route path="/service" element={<Service />}>
          <Route index element={<ServiceList />} />
        </Route>
        <Route path="/product" element={<Product />}>
          <Route index element={<ProductList />} />
        </Route>
        <Route path="/ctg" element={<Product />}>
          <Route path=":slug" element={<PerCategory />} />
        </Route>
        <Route path="/search" element={<Product />}>
          <Route path=":keyword" element={<ProductSearch />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App;

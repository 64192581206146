import React from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'

const AboutLanding = () => {
  return (
    <div>
      <div className='grid md:grid-cols-2 gap-6 items-center mb-24'>
        <div
          className='lg:pr-36'
          data-aos='fade-up'
          data-aos-duration='1000'
          data-aos-easing='ease-in-out'
        >
          <img src='/images/about-1.png' alt='abidin' />
        </div>
        <div
          data-aos='fade-down'
          data-aos-duration='1000'
          data-aos-easing='ease-in-out'
          className='lg:p-24 px-4 px-lg-0'
        >
          <h2 className='text-[24px] md:text-[35px] text-[#2E7462] mb-6 font-semibold md:leading-[50px]'>
            We Helping Boost Your Bussiness Through Remarkable Technology
          </h2>
          <p className='text-sm md:text-[16px] text-[#4F5665] leading-[30px]'>
            Built in the 2021, AMS (PT Abidin Madani Sejahtera) was meant and
            always seek to improve how people doing their business. Embracing
            digitalization approach as our core, we helps companies adopt
            sophisticated digital signage and display, and revolutionizes their
            control room
          </p>
        </div>
      </div>
      <div className='grid md:grid-cols-2 gap-6 items-center mb-24'>
        <div
          data-aos='fade-down'
          data-aos-duration='1000'
          data-aos-easing='ease-in-out'
          className='pl-4 lg:pl-36'
        >
          <h2 className='text-[24px] md:text-[35px] text-[#2E7462] mb-6 font-semibold md:leading-[50px]'>
            We Put Integrity Over Anything
          </h2>
          <p className='text-sm md:text-[16px] text-[#4F5665] leading-[30px]'>
            Explore our exceptional features, designed to enhance your
            experience and optimize your results.
          </p>
          <div className='grid grid-cols-2 py-6'>
            <div className='my-3 flex gap-1.5 items-center text-[#4F5665]'>
              <BsFillCheckCircleFill className='text-[#2FAB73]' fontSize={18} />
              <span className='text-sm md:text-[16px]'>
                High Speed Response
              </span>
            </div>
            <div className='my-3 flex gap-1.5 items-center text-[#4F5665]'>
              <BsFillCheckCircleFill className='text-[#2FAB73]' fontSize={18} />
              <span className='text-sm md:text-[16px]'>
                SAMSUNG Gold Partner
              </span>
            </div>
            <div className='my-3 flex gap-1.5 items-center text-[#4F5665]'>
              <BsFillCheckCircleFill className='text-[#2FAB73]' fontSize={18} />
              <span className='text-sm md:text-[16px]'>Adjustable Budget</span>
            </div>
            <div className='my-3 flex gap-1.5 items-center text-[#4F5665]'>
              <BsFillCheckCircleFill className='text-[#2FAB73]' fontSize={18} />
              <span className='text-sm md:text-[16px]'>One-Stop Solutions</span>
            </div>
            <div className='my-3 flex gap-1.5 items-center text-[#4F5665]'>
              <BsFillCheckCircleFill className='text-[#2FAB73]' fontSize={18} />
              <span className='text-sm md:text-[16px]'>Quality SLA</span>
            </div>
          </div>
        </div>
        <div
          className='md:pl-24'
          data-aos='fade-up'
          data-aos-duration='1000'
          data-aos-easing='ease-in-out'
        >
          <img src='/images/about-2.png' alt='abidin' />
        </div>
      </div>
      <div className='grid md:grid-cols-2 gap-6 items-center mb-24'>
        <div
          className='lg:pr-0'
          data-aos='fade-up'
          data-aos-duration='1000'
          data-aos-easing='ease-in-out'
        >
          <img src='/images/about-3.png' alt='abidin' />
        </div>
        <div
          data-aos='fade-down'
          data-aos-duration='1000'
          data-aos-easing='ease-in-out'
          className='lg:p-24 px-4 px-lg-0'
        >
          <h2 className='text-[24px] md:text-[35px] text-[#2E7462] mb-6 font-semibold md:leading-[50px]'>
            Trustworthy and Reliable as Our Bussiness are Registered in
            E-Catalogue
          </h2>
          <p className='text-sm md:text-[16px] text-[#4F5665] leading-[30px]'>
            Being registered in the E-Catalogue is a testament to our commitment
            to providing quality products and services to our customers. It also
            means that we are able to participate in public procurement
            processes, which can open up new opportunities for us to grow and
            expand our business.
          </p>
        </div>
      </div>
    </div>
  )
}

export default AboutLanding

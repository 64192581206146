import { FaSearch } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const SecondHeader = ({
  title = 'Article',
  query,
  slug,
  activePath,
  onSearch,
  onChange
}) => {
  const navigate = useNavigate()
  return (
    <div className='bg-second-header'>
      <div className='px-4 lg:px-14 py-4'>
        <h1 className='text-[36px] text-white'>{title}</h1>
        <div className='lg:flex items-end mt-4'>
          <div className='flex-1 text-lg text-white flex gap-5 mb-3 lg:mb-0'>
            <button onClick={() => navigate('/articles/category/trivia')}>
              Trivia
            </button>
            <button onClick={() => navigate('/articles/category/technology')}>
              Technology
            </button>
            <button onClick={() => navigate('/articles/category/bussiness')}>
              Bussiness
            </button>
          </div>
          <div className='flex-1 flex gap-5'>
            <div className='flex-1 focus-within:ring focus-within:ring-green-500 focus-within:right-1 flex gap-2 items-center border bg-white p-2 rounded-lg'>
              <FaSearch className='text-slate-500' />
              <input
                onChange={onChange}
                placeholder='search'
                type='text'
                className='w-full outline-none bg-transparent'
              />
            </div>
            <button
              onClick={onSearch}
              className='py-2 px-6 bg-[#FFBF1A] rounded-lg'
            >
              <span>Search</span> <span className='hidden lg:inline'>Now</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondHeader

import getCustomMonth from "src/data/month"
const dateIndo = (data) => {
    const date = new Date(data)
    const D = date.getDate()
    const M = getCustomMonth(date.getMonth())
    const Y = date.getFullYear()
    return `${D} ${M} ${Y}`
}


export { dateIndo }
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { BsChevronRight } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'
const ModalEccomerce = ({ isOpen, setIsOpen, data = [] }) => {
  function closeModal () {
    setIsOpen(false)
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-xl bg-white shadow-md rounded-xl overflow-hidden transition-all'>
                  <button
                    onClick={closeModal}
                    className='absolute flex items-center justify-center text-white rounded-lg z-40 right-1.5 top-1.5 h-8 w-8 bg-red-500'
                  >
                    <MdClose fontSize={22} />
                  </button>
                  <div className='bg-primary-lighten p-4 text-center'>
                    <h3 className='text-xl font-semibold'>
                      Choose the marketplace
                    </h3>
                    <p className='text-slate-200'>
                      Shop our products on the online store of your choice
                    </p>
                  </div>
                  <div className='p-5'>
                    {data?.map(item => (
                      <div
                        key={item.id}
                        className='md:flex gap-3 justify-between items-center py-3 px-5 my-4 rounded-xl bg-[#E7EDF5]'
                      >
                        <div className='w-24 flex mx-auto justify-center'>
                          <img
                            className='w-20 h-9 object-contain'
                            src={
                              process.env.REACT_APP_IMAGE +
                              '/' +
                              item?.ecommerces?.imageIcon
                            }
                            alt='ecatalag'
                          />
                        </div>
                        <div className='flex-1 text-start text-[#7C7C7C]'>
                          <span>Purchase from</span>
                          <span className='font-semibold ml-2'>
                            {item?.ecommerces.title}
                          </span>
                        </div>
                        <a
                          className='flex gap-2 items-center my-1 md:my-0 bg-warning py-2 px-3 hover:bg-[#364FC7] duration-200 text-white rounded-md'
                          href={item?.product_link}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <span className='text-sm'>Buy</span>
                          <BsChevronRight />
                        </a>
                      </div>
                    ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ModalEccomerce

import React from 'react'

const ProductHeader = ({
    title
}) => {
    return (
        <div className='md:w-2/3'>
            <h2 className='text-[18px] md:text-[35px] font-[500] text-primary'>{title}</h2>
        </div>
    )
}

export default ProductHeader
import React from 'react'
import { BsGrid, BsTag } from 'react-icons/bs'

const CardProduct = ({ category, onClick, data }) => {
  const IMG = process.env.REACT_APP_IMAGE + '/' + data?.image
  return (
    <div className='border rounded-lg overflow-hidden'>
      <div className='aspect-video'>
        <img
          className='w-full h-full object-cover'
          src={IMG || '/images/product.png'}
          alt='product'
        />
      </div>
      <div className='flex p-3 justify-between gap-2 items-start'>
        <div className='flex-1 h-12 line-clamp-2 text-poppins uppercase font-semibold text-[#282828]'>
          {data?.name || 'name'}
        </div>
        {data?.status_stock ? (
          <div className='text-[8px] w-20 flex justify-center items-center rounded p-[2px] text-white bg-[#2E7462]'>
            Stock Available
          </div>
        ) : (
          <div className='text-[8px] w-20 flex justify-center items-center rounded p-[2px] text-white bg-[#de1f1f]'>
            Stock Unavailable
          </div>
        )}
      </div>
      <div className='border-b mx-3' />
      <div className='flex flex-wrap gap-2 justify-between p-3'>
        <div className='flex gap-1 items-center'>
          <BsGrid />
          <span className='text-[11px] font-light'>{category}</span>
        </div>
        <div className='flex gap-1 items-center'>
          <BsTag />
          <span className='text-[11px] font-light'>{data?.brand}</span>
        </div>
      </div>
      <div className='p-3'>
        <button
          onClick={onClick}
          className='bg-[#2FAB73] p-2.5 text-white w-full rounded-lg'
        >
          Shop Now
        </button>
      </div>
    </div>
  )
}

export default CardProduct

import classNames from 'classnames'
import { BsGrid, BsXLg } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { toggleMenu } from 'src/redux/global/menuSlice'

const Navbar = () => {
  const dispatch = useDispatch()
  const stateMenu = useSelector(state => state.menu)

  const handleClickMenu = () => {
    dispatch(toggleMenu(!stateMenu.value))
  }
  const ClassInActive = classNames({
    'text-primary font-normal relative': true
  })
  const ClassActive = classNames({
    'text-primary font-normal relative activeMenu': true
  })
  return (
    <div className='bg-[#F6F7F9] px-3 xl:px-0 sticky top-0 z-50'>
      <div className='px-4 md:px-8 h-[72px] md:h-[112px] flex items-center'>
        <div className='w-[96px] md:w-[132px] md:ml-8'>
          <Link to={'/'}>
            <img
              className='w-full'
              src='/logo/logo-full-2.png'
              alt='abidin logo'
            />
          </Link>
        </div>
        <div className='flex-1 flex items-center justify-end gap-[40px]'>
          <div className='hidden md:flex gap-[40px]'>
            <NavLink
              to={'/'}
              className={({ isActive }) =>
                isActive ? ClassActive : ClassInActive
              }
            >
              <span>Home</span>
            </NavLink>
            <NavLink
              to={'/gallery'}
              className={({ isActive }) =>
                isActive ? ClassActive : ClassInActive
              }
            >
              <span>Gallery</span>
            </NavLink>

            <NavLink
              to={'/service'}
              className={({ isActive }) =>
                isActive ? ClassActive : ClassInActive
              }
            >
              <span>Service</span>
            </NavLink>
            <NavLink
              to={'/product'}
              className={({ isActive }) =>
                isActive ? ClassActive : ClassInActive
              }
            >
              <span>Product</span>
            </NavLink>
            <NavLink
              to={'/articles'}
              className={({ isActive }) =>
                isActive ? ClassActive : ClassInActive
              }
            >
              <span>Articles</span>
            </NavLink>
          </div>
          <div className='flex'>
            <div className='py-3 hidden md:flex'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://api.whatsapp.com/send?phone=6281211118491&text=Hai! saya ingin menanyakan tentang servis dan produk dari Abidin Madani.'
                className='p-1.5 px-2 md:py-3 md:px-6 hover:bg-primary group border border-primary text-primary rounded-full'
              >
                <span className='text-sm md:text-base block duration-200'>
                  Contact Us
                </span>
              </a>
            </div>
            <button
              onClick={handleClickMenu}
              className='py-3 md:hidden text-primary'
            >
              {stateMenu.value ? (
                <BsXLg fontSize={20} />
              ) : (
                <BsGrid fontSize={28} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar

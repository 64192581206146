import React from 'react'

const InputCustom = ({
  icon,
  placeholder,
  type,
  name,
  id,
  className,
  isLongText,
  ...rest
}) => {
  return (
    <div
      className={`flex items-center border p-2.5 rounded-lg gap-2 ${className}`}
    >
      {icon && <div className='text-slate-400'>{icon}</div>}
      {isLongText ? (
        <textarea
          {...rest}
          name={name}
          id={id}
          placeholder={placeholder}
          rows={5}
          className={`w-full resize-none bg-transparent outline-none`}
        />
      ) : (
        <input
          {...rest}
          name={name}
          id={id}
          type={type}
          placeholder={placeholder}
          className={`w-full bg-transparent outline-none`}
        />
      )}
    </div>
  )
}

export default InputCustom

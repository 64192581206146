import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { Navigation } from 'swiper'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
const SliderGallery = ({ images }) => {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  return (
    <>
      <Swiper
        // navigation={{
        //   prevEl: navigationPrevRef.current,
        //   nextEl: navigationNextRef.current
        // }}
        navigation={true}
        loop={true}
        modules={[Navigation]}
        className='relative group h-[50vh] md:h-[100vh]'
      >
        {images?.map(item => (
          <SwiperSlide key={item.id}>
            <div className='h-full bg-slate-100'>
              <img
                className='h-full w-full object-cover'
                src={process.env.REACT_APP_IMAGE + '/' + item.image}
                alt='gallery madani'
              />
            </div>
          </SwiperSlide>
        ))}
        {/* <SwiperSlide>
          <div className='h-full bg-slate-100'>
            <img
              className='h-full w-full object-cover'
              src='/images/gallery/3.png'
              alt='gallery madani'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='h-full bg-slate-100'>
            <img
              className='h-full w-full object-cover'
              src='/images/gallery/4.png'
              alt='gallery madani'
            />
          </div>
        </SwiperSlide> */}
        {/* <div
          ref={navigationPrevRef}
          className='absolute z-20 h-full bottom-0 left-0 flex justify-center items-center w-16'
        >
          <button className='cursor-pointer h-10 w-10 text-white bg-[rgba(0,0,0,.3)] group-hover:bg-white group-hover:text-slate-600 shadow duration-150 flex items-center justify-center rounded-full'>
            <FaChevronLeft />
          </button>
        </div>
        <div
          ref={navigationNextRef}
          className='absolute z-20 h-full bottom-0 right-0 flex justify-center items-center w-16'
        >
          <button className='cursor-pointer h-10 w-10 text-white bg-[rgba(0,0,0,.3)] group-hover:bg-white group-hover:text-slate-600 shadow duration-150 flex items-center justify-center rounded-full'>
            <FaChevronRight />
          </button>
        </div> */}
      </Swiper>
    </>
  )
}

export default SliderGallery

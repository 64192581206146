import React from 'react'

const ButtonOutline = ({ title, ...rest }) => {
    return (
        <button
            {...rest}
            className='p-1.5 px-2 md:py-[8px] group hover:bg-primary-lighten md:px-6 border border-primary-lighten text-primary-lighten rounded-full'>
            <span className='text-sm md:text-base block duration-200'>{title}</span>
        </button>
    )
}

export default ButtonOutline
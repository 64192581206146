import axios from 'axios'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import CardArticle from 'src/components/molecules/CardArticle'
import CardLoader from 'src/components/orgainsms/CardProduct/CardLoader'
import SecondHeader from 'src/components/orgainsms/SecondHeader'
import useSwr from 'swr'

const fether = async url => {
  const response = await axios(url)
  return response.data
}

const ArticleSearch = () => {
  const API = process.env.REACT_APP_API
  const location = useLocation()
  const k = location.pathname.split('/').pop()
  const [keyword, setKeyword] = useState(k)
  const { data, isLoading } = useSwr(
    API + '/search/article?keyword=' + keyword,
    fether
  )
  return (
    <div>
      <SecondHeader onChange={e => setKeyword(e.target.value)} />
      <div className='px-4 lg:px-14 pt-6 pb-32'>
        <div className='mt-[50px]'>
          <div className='mb-3'>
            <span>
              {data?.length} result found with keyword "{keyword}"
            </span>
          </div>
          <div className='grid grid-cols-2 lg:grid-cols-3 gap-[30px]'>
            {isLoading && (
              <>
                {[1, 2, 3, 4, 5, 6].map(item => (
                  <CardLoader type={'horizontal'} key={item} />
                ))}
              </>
            )}
            {data?.map(art => (
              <CardArticle
                type='horizontal'
                key={art.id}
                title={art.title}
                slug={art.slug}
                image={process.env.REACT_APP_IMAGE + '/article/' + art.image}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleSearch

import React from 'react'
import { BsFillImageFill } from 'react-icons/bs'
import TextLoader from './TextLoader'

const BannerLoader = () => {
    return (
        <div className='h-[40vh] px-12 text-slate-400 bg-slate-200 flex items-center justify-center animate-pulse'>
            <BsFillImageFill fontSize={128} />
        </div>
    )
}

export default BannerLoader
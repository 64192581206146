import { BsGrid } from 'react-icons/bs'
import { FaArrowRight } from 'react-icons/fa'
import { RxCalendar } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import CardLoader from '../CardProduct/CardLoader'

const LatestArticle = ({ data, isLoading }) => {
  const navigate = useNavigate()
  const handleClick = item => {
    navigate(`/articles/${item.slug}`, {
      state: { slug: item.slug, title: item.title }
    })
  }
  return (
    <div className='bg-last-article pt-24 pb-52 -mt-1 px-4 lg-px-0'>
      <h2 className='text-[36px] font-semibold text-center text-white mb-20'>
        Last Articles
      </h2>
      <div className='w-full max-w-5xl mx-auto'>
        <div className='grid md:grid-cols-3 gap-8'>
          {isLoading && (
            <>
              <CardLoader />
              <CardLoader />
              <CardLoader />
            </>
          )}
          {data?.map(item => (
            <div
              key={item.id}
              className='bg-white hover:shadow-2xl hover:scale-105 duration-500 shadow-lg rounded-xl overflow-hidden'
            >
              <div className='h-52'>
                <img
                  className='w-full h-full object-cover'
                  src={
                    process.env.REACT_APP_IMAGE + '/article/' + item.image ||
                    '/images/articles/3.png'
                  }
                  alt='article'
                />
              </div>
              <div className='p-[20px]'>
                <div className='flex items-center gap-1'>
                  <BsGrid className='text-[#2FAB73]' />
                  <span className='text-secondary'>
                    {item?.category?.title}
                  </span>
                </div>
                <p className='my-4 line-clamp-1 font-semibold'>{item.title}</p>
                <div className='flex justify-between'>
                  <div className='flex text-secondary items-center gap-2'>
                    <RxCalendar />
                    <span>{item.published_at.split(' ')[0]}</span>
                  </div>
                  <button
                    onClick={() => handleClick(item)}
                    className='flex items-center gap-2'
                  >
                    <span className='text-[#2FAB73]'>Read more</span>
                    <FaArrowRight className='text-[#2E7462]' />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LatestArticle

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const SearchForm = () => {
    const navigate = useNavigate()
    const [keyword, setKeyword] = useState('')
    const handleSearch = (e) => {
        e.preventDefault()
        if (!keyword) {
            return
        }
        navigate('/search/' + keyword)
        localStorage.setItem("recent_keyword", keyword)
    }
    return (
        <div className='bg-search-form font-poppins'>
            <form
                onSubmit={handleSearch}
                className='h-[80px] px-3 md:px-8 flex gap-3 items-center'>
                <div className='bg-white flex items-center p-2.5 flex-1 rounded-lg'>
                    <div className='w-6'>
                        <img src="/icons/_ic_search.svg" alt="search" />
                    </div>
                    <input
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder='Search'
                        className='w-full outline-none pl-2' type="text" />
                </div>
                <button className='bg-warning py-2.5 px-5 rounded-lg'>
                    <span className='hidden lg:block'>Search Now</span>
                    <span className='lg:hidden block'>Search</span>
                </button>
            </form>
        </div>
    )
}

export default SearchForm
import React from 'react'
import BannerLoader from './BannerLoader'
import TextLoader from './TextLoader'

const ArticleLoader = () => {
  return (
    <div className='animate-pulse py-12 px-4 lg:px-14'>
      <div className='my-4 flex flex-col gap-4 w-full'>
        <TextLoader />
        <TextLoader />
        <TextLoader />
      </div>
      <BannerLoader />
      <div className='my-4 flex flex-col gap-2 max-w-sm w-full'>
        <TextLoader />
        <TextLoader />
      </div>
      <div className='my-4 flex flex-col gap-2'>
        <TextLoader />
        <TextLoader />
        <div className='my-5' />
      </div>
      <div className='my-4 flex flex-col gap-2 max-w-5xl ml-auto w-full'>
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <div className='my-5' />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <div className='my-5' />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <TextLoader />
        <TextLoader />
      </div>
    </div>
  )
}

export default ArticleLoader

import React from 'react'
import { BsDot, BsX } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom'
import SliderGallery from 'src/components/molecules/SliderGallery'
import parse from 'html-react-parser'

const DetailGallery = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  console.log(state)
  return (
    <div className='px-4 lg:px-14 bg-slate-100'>
      <button
        onClick={() => navigate(-1)}
        className='text-3xl fixed z-40 top-6 right-6'
      >
        <BsX />
      </button>
      <div className='grid items-start grid-cols-1 md:grid-cols-3'>
        <div className='col-span-1 md:col-span-2 sticky top-0'>
          <SliderGallery images={state?.image} />
        </div>
        <div className='col-span-1 overflow-auto py-[60px] px-[30px]'>
          <div>
            <h1 className='text-[#2E7462] text-[46px] leading-[65px] font-[500]'>
              {state?.title}
            </h1>
          </div>
          <div className='border-b border-[#D1D1D1] my-[20px]' />
          <div>
            <div className='flex gap-4'>
              <span className='text-[#2E7462] font-semibold w-24 mb-8'>
                Location
              </span>
              <span className='flex-1 text-[#4F5665]'>{state?.location}</span>
            </div>
            <div className='flex gap-4'>
              <span className='text-[#2E7462] font-semibold w-24 mb-8'>
                Price
              </span>
              <span className='flex-1 text-[#4F5665]'>{state?.price}</span>
            </div>
            <div className='flex gap-4'>
              <span className='text-[#2E7462] font-semibold w-24 mb-8'>
                Year
              </span>
              <span className='flex-1 text-[#4F5665]'>{state?.tgl}</span>
            </div>
          </div>
          <div className='mb-[22px]'>
            <h2 className='text-[#2E7462] text-[30px] font-[500] mb-[12px]'>
              Project Details
            </h2>
            <div className='text-[#4F5665] leading-[30px]'>
              {parse(state?.project_details)}
            </div>
          </div>
          <div>
            <h2 className='text-[#2E7462] text-[30px] font-[500] mb-[12px]'>
              Main Product
            </h2>
            <div className='prose prose-slate'>
              {parse(state?.main_product)}
            </div>
            {/* <div className='flex items-center text-[#4F5665] font-[400]'>
              <div className='w-6'>
                <BsDot />
              </div>
              <div className='flex-1'>CCTV 1</div>
            </div>
            <div className='flex items-center text-[#4F5665] font-[400]'>
              <div className='w-6'>
                <BsDot />
              </div>
              <div className='flex-1'>Videowall 1</div>
            </div>
            <div className='flex items-center text-[#4F5665] font-[400]'>
              <div className='w-6'>
                <BsDot />
              </div>
              <div className='flex-1'>Processor 1</div>
            </div>
            <div className='flex items-center text-[#4F5665] font-[400]'>
              <div className='w-6'>
                <BsDot />
              </div>
              <div className='flex-1'>Cable 1</div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailGallery

import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    value: false
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        toggleMenu: (state, action) => {
            state.value = action.payload
        },
    },
})
export const { toggleMenu } = menuSlice.actions

export default menuSlice.reducer
import React from 'react'

const Announcement = ({
    text = ""
}) => {
    return (
        <div className='bg-announcement w-full flex items-center px-6 py-2'>
            <div className='flex justify-center'>
                <img className='w-full object-contain' src="/icons/_ic_star_2.svg" alt="start" />
            </div>
            <div className='flex-1 text-lg text-primary max-w-xl mx-auto'>
                {text}
            </div>
        </div>
    )
}

export default Announcement
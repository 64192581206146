import { Listbox, Transition } from '@headlessui/react'
import axios from 'axios'
import { Fragment, useState } from 'react'
import {
  BsCheckLg,
  BsChevronLeft,
  BsChevronRight,
  BsFillCaretDownFill
} from 'react-icons/bs'
import { useLocation } from 'react-router-dom'
import BannerLoader from 'src/components/atoms/BannerLoader'
import ProductHeader from 'src/components/atoms/ProductHeader'
import TextLoader from 'src/components/atoms/TextLoader'
import ModalEccomerce from 'src/components/molecules/ModalEcommerce'
import CardProduct from 'src/components/orgainsms/CardProduct'
import CardLoader from 'src/components/orgainsms/CardProduct/CardLoader'
import useSwr from 'swr'
const filterList = [
  { name: 'Sort by' },
  { name: 'Produk', value: 'name' },
  { name: 'Terbaru', value: 'date' }
]
const fether = async url => {
  const response = await axios(url)
  return response.data
}

const PerCategory = () => {
  const [selected, setSelected] = useState(filterList[0])
  const location = useLocation()
  const [sort, setSort] = useState('date')
  const [page, setPage] = useState('1')
  const API = process.env.REACT_APP_API
  const query = `?category=${location.state.id}&order_by=${sort}&page=${page}&per_page=10`
  const { data, isLoading, error } = useSwr(
    API + '/product/filter' + query,
    fether
  )
  let [isOpen, setIsOpen] = useState(false)
  const [listing, setListing] = useState(null)
  const products = data?.products?.data
  let prevPage = data?.products?.prev_page_url
  let nextPage = data?.products?.next_page_url
  let currentPage = data?.products?.current_page
  let lastPage = data?.products?.last_page

  const handleOpenModal = data => {
    setIsOpen(true)
    setListing(data)
  }
  const handleChangeSort = e => {
    setSelected(e)
    setSort(e.value)
  }
  return (
    <>
      <div className='p-4 md:p-8 md:flex gap-3 items-center justify-end'>
        <span className='text-[#282828]'>Sort By</span>
        <div className='w-full md:w-72'>
          <Listbox value={selected} onChange={handleChangeSort}>
            <div className='relative  mt-1'>
              <Listbox.Button className='relative w-full cursor-ponter border rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 sm:text-sm'>
                <span className='block truncate'>{selected.name}</span>
                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                  <BsFillCaretDownFill className='h-3 w-3' aria-hidden='true' />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {filterList.map((list, listIdx) => (
                    <Listbox.Option
                      key={listIdx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                          active
                            ? 'bg-green-100 text-green-700'
                            : 'text-gray-900'
                        }`
                      }
                      value={list}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {list.name}
                          </span>
                          {selected ? (
                            <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-green-600'>
                              <BsCheckLg
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
      <div>
        {products ? (
          <Fragment>
            {products?.length > 0 && (
              <img
                className='w-full object-contain md:object-cover'
                src={`${process.env.REACT_APP_IMAGE}/${products[0]?.category?.image}`}
                alt='ctg'
              />
            )}
          </Fragment>
        ) : (
          <BannerLoader />
        )}
      </div>
      <div className='p-4 md:p-8'>
        {isLoading && (
          <div className='mb-20'>
            <div className='grid grid-cols-4 mb-12'>
              <div className='col-span-1 mb-3'>
                <TextLoader />
              </div>
              <div className='col-span-4'>
                <TextLoader />
                <div className='my-2' />
                <TextLoader />
              </div>
            </div>
            <div className='grid gap-4 md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4'>
              <CardLoader />
              <CardLoader />
              <CardLoader />
              <CardLoader />
            </div>
          </div>
        )}

        <div className='mb-8'>
          <ProductHeader title={data?.category?.name} />
          <p className='text-[#4F5665]'>{data?.category?.description}</p>
        </div>
        {!isLoading && !products.length && (
          <div>
            <span className='text-xl text-red-500'>Data Not Found!</span>
          </div>
        )}
        <div className='grid gap-4 md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4'>
          {products?.map(item => (
            <CardProduct
              key={item.id}
              onClick={() => handleOpenModal(item.produk_listing)}
              category={location.state.name}
              data={item}
            />
          ))}
        </div>
        <div className='mt-24 mb-12 flex justify-center items-center'>
          {prevPage ? (
            <button
              onClick={() => setPage(currentPage - 1)}
              className='h-[42px] w-[42px] flex items-center justify-center text-[20px] font-light'
            >
              <BsChevronLeft />
            </button>
          ) : (
            <div className='h-[42px] w-[42px] flex items-center text-slate-300 justify-center text-[20px] font-light'>
              <BsChevronLeft />
            </div>
          )}

          {[...Array(lastPage).keys()]?.map(item => {
            return (
              <button
                onClick={() => setPage(item + 1)}
                key={item}
                className={`
                ${
                  item + 1 === currentPage
                    ? 'text-primary-lighten font-semibold'
                    : 'text-slate-700'
                }
                 h-[42px] w-[42px] flex items-center justify-center text-[20px] font-light`}
              >
                {item + 1}
              </button>
            )
          })}
          {nextPage ? (
            <button
              onClick={() => setPage(currentPage + 1)}
              className='h-[42px] w-[42px] flex items-center justify-center text-[20px] font-light'
            >
              <BsChevronRight />
            </button>
          ) : (
            <div className='h-[42px] w-[42px] flex items-center text-slate-300 justify-center text-[20px] font-light'>
              <BsChevronRight />
            </div>
          )}
        </div>
      </div>
      <ModalEccomerce isOpen={isOpen} setIsOpen={setIsOpen} data={listing} />
    </>
  )
}

export default PerCategory

import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper";

const HeroLanding = () => {
    return (
        <>
            <Swiper
                pagination={{
                    clickable: true
                }}
                modules={[Pagination]}
                className="h-[50vh] lg:h-[80vh]">
                <SwiperSlide>
                    <div className="w-full h-full relative">
                        <img
                            className="h-full w-full object-cover"
                            src="/images/hero/1.png" alt="abidin"
                        />
                        <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center bg-[rgba(0,0,0,0.2)]">
                            <div className="flex w-[90vw] md:w-[70vw] lg:w-2/5 h-[140px] md:h-[200px] gap-2">
                                <div className="bg-hero-text flex flex-col justify-center items-center h-full flex-1">
                                    <div>
                                        <span className="text-2xl md:text-3xl text-white">Telah Terdaftar di</span>
                                        <h3 className="text-4xl md:text-5xl text-white font-semibold">E-Catalogue</h3>
                                    </div>
                                </div>
                                <div className="bg-[#FFBF1A] w-[18px] h-full" />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="w-full h-full relative">
                        <img
                            className="h-full w-full object-cover"
                            src="/images/hero/2.png" alt="abidin"
                        />
                        <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center bg-[rgba(0,0,0,0.2)]">
                            <div className="flex w-[90vw] md:w-[70vw] lg:w-2/5 h-[140px] md:h-[200px] gap-2">
                                <div className="bg-hero-text flex flex-col justify-center items-center h-full flex-1">
                                    <div>
                                        <span className="text-2xl md:text-3xl text-white">Telah Terdaftar di</span>
                                        <h3 className="text-4xl md:text-5xl text-white font-semibold">E-Catalogue</h3>
                                    </div>
                                </div>
                                <div className="bg-[#FFBF1A] w-[18px] h-full" />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default HeroLanding
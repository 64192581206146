import axios from 'axios'
import SubscribeFlotaing from 'src/components/molecules/SubscribeFloating'
import AboutLanding from 'src/components/orgainsms/HomeSection/AboutLanding'
import HeroLanding from 'src/components/orgainsms/HomeSection/HeroLanding'
import LatestArticle from 'src/components/orgainsms/HomeSection/LatestArticle'
import OurClient from 'src/components/orgainsms/HomeSection/OurClient'
import ProductService from 'src/components/orgainsms/HomeSection/ProductService'
import WhyChoose from 'src/components/orgainsms/HomeSection/WhyChoose'
import useSwr from 'swr'
const fether = async url => {
  const response = await axios(url)
  return response.data
}
const Landing = () => {
  const API = process.env.REACT_APP_API
  const { data, isLoading } = useSwr(API + '/article-latest', fether)
  return (
    <>
      <HeroLanding />
      <OurClient />
      <AboutLanding />
      <ProductService />
      <WhyChoose />
      <LatestArticle data={data} isLoading={isLoading} />
      <SubscribeFlotaing />
    </>
  )
}

export default Landing

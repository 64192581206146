import axios from 'axios'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ButtonOutline from 'src/components/atoms/ButtonOutline'
import CardArticle from 'src/components/molecules/CardArticle'
import SubscribeFlotaing from 'src/components/molecules/SubscribeFloating'
import CardLoader from 'src/components/orgainsms/CardProduct/CardLoader'
import SecondHeader from 'src/components/orgainsms/SecondHeader'
import useSwr from 'swr'

const fether = async url => {
  const response = await axios(url)
  return response.data
}

const ArticleList = () => {
  const navigate = useNavigate()
  const API = process.env.REACT_APP_API
  const { data, isLoading } = useSwr(API + '/article', fether)
  const [keyword, setKeyword] = useState('')

  const handleSearch = () => {
    navigate({
      pathname: '/articles/search/' + keyword
    })
  }

  const goToPage = path => {
    navigate('/articles/category/' + path)
  }

  return (
    <>
      <SecondHeader
        onSearch={handleSearch}
        onChange={e => setKeyword(e.target.value)}
      />
      <div className='px-4 lg:px-14 pt-6 pb-32'>
        {isLoading && (
          <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-5'>
            {[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
              <CardLoader key={item} />
            ))}
          </div>
        )}
        {data?.map(item => (
          <Fragment key={item.id}>
            {item.title === 'Trivia' && (
              <div className='mb-[12px] lg:mb-[120px]'>
                <div className='py-8'>
                  <div className='flex justify-between items-end'>
                    <h2 className='text-[#2FAB73] text-[24px]' id='trivia'>
                      Trivia
                    </h2>
                    <ButtonOutline
                      onClick={() => goToPage(item.slug)}
                      title={'see more...'}
                    />
                  </div>
                </div>
                <div className='grid grid-cols-2 lg:grid-cols-4 gap-[10px] md:gap-[30px]'>
                  {item.articles.map(art => (
                    <CardArticle
                      publishedAt={art.published_at}
                      key={art.id}
                      title={art.title}
                      slug={`/articles/${art.slug}`}
                      image={
                        process.env.REACT_APP_IMAGE + '/article/' + art.image
                      }
                    />
                  ))}
                </div>
              </div>
            )}
            {item.title === 'Technology' && (
              <div className='mb-[12px] lg:mb-[120px]'>
                <div className='py-8'>
                  <div className='flex justify-between items-end'>
                    <h2 className='text-[#2FAB73] text-[24px]' id='technology'>
                      Technology
                    </h2>
                    <ButtonOutline
                      onClick={() => goToPage(item.slug)}
                      title={'see more...'}
                    />
                  </div>
                </div>
                <div className='grid lg:grid-cols-3 gap-x-[70px]'>
                  {item.articles.map((art, item) => (
                    <Fragment key={art.id}>
                      {item === 0 ? (
                        <div className='lg:col-span-1  mb-12 lg:mb-0'>
                          <CardArticle
                            publishedAt={art.published_at}
                            key={art.id}
                            hilight={true}
                            title={art.title}
                            slug={`/articles/${art.slug}`}
                            image={
                              process.env.REACT_APP_IMAGE +
                              '/article/' +
                              art.image
                            }
                          />
                        </div>
                      ) : (
                        <div className='lg:col-span-2'>
                          <div className='grid sm:grid-cols-2 gap-y-[30px] gap-x-[96px]'>
                            {item > 0 && item < 10 && (
                              <CardArticle
                                publishedAt={art.published_at}
                                key={art.id}
                                title={art.title}
                                slug={`/articles/${art.slug}`}
                                type='horizontal'
                                image={
                                  process.env.REACT_APP_IMAGE +
                                  '/article/' +
                                  art.image
                                }
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            )}
            {item.title === 'Bussiness' && (
              <div className='mb-[12px] lg:mb-[120px]'>
                <div className='py-8'>
                  <div className='flex justify-between items-end'>
                    <h2 className='text-[#2FAB73] text-[24px]' id='bussiness'>
                      Bussiness
                    </h2>
                    <ButtonOutline
                      onClick={() => goToPage(item.slug)}
                      title={'see more...'}
                    />
                  </div>
                </div>
                <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-12'>
                  {item.articles.map(art => (
                    <CardArticle
                      publishedAt={art.published_at}
                      type='horizontal'
                      key={art.id}
                      title={art.title}
                      slug={`/articles/${art.slug}`}
                      image={
                        process.env.REACT_APP_IMAGE + '/article/' + art.image
                      }
                    />
                  ))}
                </div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
      <SubscribeFlotaing />
    </>
  )
}

export default ArticleList

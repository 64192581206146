import { BsFillImageFill } from 'react-icons/bs'
import TextLoader from 'src/components/atoms/TextLoader'

const CardLoader = ({ type }) => {
  return (
    <>
      {type === 'horizontal' ? (
        <div className='group flex h-32 md:h-36 hover:shadow-lg'>
          <div className='aspect-square text-slate-400 bg-slate-200 flex items-center justify-center animate-pulse'>
            <BsFillImageFill fontSize={56} />
          </div>
          <div className='p-[8px] lg:p-[20px] w-full group-hover:bg-[#2E7462] transition-all duration-300'>
            <div className='flex flex-col gap-4'>
              <TextLoader />
              <TextLoader />
              <TextLoader />
              <TextLoader />
            </div>
          </div>
        </div>
      ) : (
        <div className='border rounded-lg bg-white overflow-hidden'>
          <div className='aspect-video text-slate-400 bg-slate-200 flex items-center justify-center animate-pulse'>
            <BsFillImageFill fontSize={56} />
          </div>
          <div className='flex p-3 justify-between gap-2 items-start'>
            <div className='flex-1 text-poppins uppercase font-semibold text-[#282828]'>
              <TextLoader />
            </div>
          </div>
          <div className='border-b mx-3' />
          <div className='flex gap-2 justify-between p-3'>
            <TextLoader />
            <TextLoader />
          </div>
          <div className='p-3'>
            <TextLoader />
            <div className='my-2' />
            <TextLoader />
          </div>
        </div>
      )}
    </>
  )
}

export default CardLoader

import React from 'react'
import { Link } from 'react-router-dom'
import { dateIndo } from 'src/helper/date'

const CardArticle = ({
  type = 'vertical',
  highlight,
  slug,
  publishedAt = '2023 01 01',
  title = 'Title blm ada isi',
  image = '/images/articles/2.png'
}) => {
  return (
    <>
      {type === 'vertical' ? (
        <>
          {highlight ? (
            <Link
              to={slug}
              state={{ slug: slug, title: title }}
              className='group  hover:bg-[#2E7462] hover:shadow-lg aspect-auto block'
            >
              <div className='h-[291px]'>
                <img
                  className='w-full h-full object-cover'
                  src={image}
                  alt='article'
                />
              </div>
              <div className='p-[45px] transition-all duration-300'>
                <span className='mb-[23px] block text-sm text-white'>
                  {dateIndo(publishedAt)}
                </span>
                <p className='line-clamp-3 leading-[35px] text-[23px] font-semibold text-white'>
                  {title}
                </p>
              </div>
            </Link>
          ) : (
            <Link
              to={slug}
              state={{ slug: slug, title: title }}
              className='group hover:shadow-lg hover:bg-[#2E7462]'
            >
              <div className='aspect-video'>
                <img
                  className='w-full h-full object-cover'
                  src={image}
                  alt='article'
                />
              </div>
              <div className='p-[12px] md:p-[30px] group-hover:bg-[#2E7462] transition-all duration-300'>
                <span className='md:mb-[23px] block text-[14px] text-[#7C7C7C] group-hover:text-[#F6F7F9]'>
                  {dateIndo(publishedAt)}
                </span>
                <p className='line-clamp-2 md:leading-[35px] md:text-[23px] font-semibold text-[#2E7462] group-hover:text-white'>
                  {title}
                </p>
              </div>
            </Link>
          )}
        </>
      ) : (
        <Link
          to={slug}
          state={{ slug: slug, title: title }}
          className='group flex h-32 md:h-36 hover:shadow-lg  hover:bg-[#2E7462]'
        >
          <div className='aspect-square bg-white'>
            <img
              className='w-full h-full object-cover'
              src={image}
              alt='article'
            />
          </div>
          <div className='p-[8px] lg:p-[20px] w-full group-hover:bg-[#2E7462] transition-all duration-300'>
            <span className='block mb-[5px] text-sm text-slate-500 group-hover:text-[#FFF]'>
              {dateIndo(publishedAt)}
            </span>
            <p className='line-clamp-3 text-[18px] md:text-[20px] font-semibold text-[#2E7462] group-hover:text-white'>
              {title}
            </p>
          </div>
        </Link>
      )}
    </>
  )
}

export default CardArticle

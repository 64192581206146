import axios from 'axios'
import parse from 'html-react-parser'
import { useLocation } from 'react-router-dom'
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share'
import ArticleLoader from 'src/components/atoms/ArticleLoader'
import CardArticle from 'src/components/molecules/CardArticle'
import getCustomMonth from 'src/data/month'
import useSwr from 'swr'
const fether = async url => {
  const response = await axios(url)
  return response.data
}
const ArticleDetail = () => {
  const location = useLocation()
  const slug = location.pathname.split('/').pop()

  const API = process.env.REACT_APP_API
  const { data, isLoading } = useSwr(API + '/article/' + slug, fether)

  const date = data ? new Date(data?.published_at) : new Date()
  const timePub = `${date.getDate()} ${getCustomMonth(
    date.getMonth()
  )} ${date.getFullYear()}`

  console.log(data)

  return (
    <>
      {!data && isLoading ? (
        <ArticleLoader />
      ) : (
        <>
          <div className='py-12 px-4 lg:px-14'>
            <div className='w-full max-w-5xl mx-auto'>
              <h1 className='text-[#2E7462] text-[48px] line-clamp-3'>
                {data?.title}
              </h1>
              <div className='my-3 text-slate-500'>
                {timePub || '3 Nov 2022'} | {data?.category?.title}
              </div>
            </div>
            <div className='my-12 rounded-xl overflow-hidden'>
              <img
                className='w-full h-full'
                src={
                  process.env.REACT_APP_IMAGE + '/article/' + data?.image ||
                  '/images/articles/3.png'
                }
                alt='header'
              />
            </div>
            <div>
              <div className='md:px-12 lg:px-24'>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-12'>
                  <div className='md:col-span-1 lg:px-4'>
                    <h2 className='text-xl font-semibold text-[#2E7462]'>
                      Share this article:
                    </h2>
                    <div className='flex flex-wrap gap-5 my-5'>
                      <WhatsappShareButton
                        url={window.location.href}
                        quote={'OK'}
                        className='Demo__some-network__share-button'
                      >
                        <WhatsappIcon size={46} round />
                      </WhatsappShareButton>
                      <TwitterShareButton
                        url={window.location.href}
                        quote={'OK'}
                        className='Demo__some-network__share-button'
                      >
                        <TwitterIcon size={46} round />
                      </TwitterShareButton>
                      <FacebookShareButton
                        url={window.location.href}
                        quote={'OK'}
                        className='Demo__some-network__share-button'
                      >
                        <FacebookIcon size={46} round />
                      </FacebookShareButton>
                      <TelegramShareButton
                        url={window.location.href}
                        quote={'OK'}
                        className='Demo__some-network__share-button'
                      >
                        <TelegramIcon size={46} round />
                      </TelegramShareButton>
                      <LineShareButton
                        url={window.location.href}
                        quote={'OK'}
                        className='Demo__some-network__share-button'
                      >
                        <LineIcon size={46} round />
                      </LineShareButton>
                    </div>
                  </div>
                  <article className='prose md:col-span-3'>
                    {parse(data?.body || '')}
                  </article>
                </div>
              </div>
            </div>
          </div>
          <div className='bg-gray-100'>
            <div className='pb-12 px-4 lg:px-14'>
              <div className='py-8'>
                <div className='flex justify-between items-end'>
                  <h2 className='text-[#2FAB73] text-xl'>Similiar News</h2>
                </div>
              </div>
              <div className='grid md:grid-cols-3 gap-12'>
                {data?.similiar?.map(item => (
                  <CardArticle
                    slug={'/articles/' + item.slug}
                    publishedAt={item.published_at}
                    image={
                      process.env.REACT_APP_IMAGE + '/article/' + item.image
                    }
                    title={item.title}
                    key={item.id}
                    type='horizontal'
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ArticleDetail

import { useEffect, useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { BiEnvelope, BiMailSend, BiMap } from 'react-icons/bi'
import { MdOutlineCall } from 'react-icons/md'
import InputCustom from 'src/components/atoms/InputCustom'

import axios from 'axios'
import { toast } from 'react-toastify'
import useSwr from 'swr'

const fether = async url => {
  const response = await axios(url)
  return response.data
}

const ServiceList = () => {
  const API = process.env.REACT_APP_API
  const { data } = useSwr(API + '/service', fether)

  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [service, setService] = useState('Produk ABIDIN MADANI')
  const [address, setAddress] = useState('')

  const [packageList, setPackageList] = useState()
  const [packageSelected, setPackageSelected] = useState(data)
  const [activeBtn, setActiveBtn] = useState(data && data[0].name)
  const handleChangeService = data => {
    setService(data.title)
    setPackageSelected(data)
    setActiveBtn(data.title)
  }

  useEffect(() => {
    setPackageList(data)
    setPackageSelected(data && data[0])
  }, [data])

  const handleSubmit = async () => {
    const data = {
      email: email,
      name: name,
      phone: phone,
      message: message + ' - ' + service,
      address: address
    }
    if (!email) {
      toast.error('Silahkan masukkan email Anda!')
      return
    }
    if (!name) {
      toast.error('Silahkan masukkan nama Anda!')
      return
    }
    if (!phone) {
      toast.error('Silahkan masukkan telepon Anda!')
      return
    }
    if (!message) {
      toast.error('Silahkan masukkan Pesan!')
      return
    }
    if (!address) {
      toast.error('Silahkan masukkan Alamat!')
      return
    }

    try {
      setLoading(true)
      const response = await axios({
        method: 'POST',
        url: process.env.REACT_APP_API + '/send-email',
        data: data
      })
      if (response.data.status === 200) {
        toast.success(
          'Terimakasih. Pesan telah terkirim, Team Abidin akan segera mengubungi anda'
        )
        setEmail('')
        setName('')
        setPhone('')
        setMessage('')
        setService('')
        setAddress('')
      } else {
        toast.warning('Pesan gagal terkirim, silahkan coba beberapa saat lagi.')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      {loading && (
        <div className='fixed z-50 left-0 right-0 top-0 bottom-0 backdrop-grayscale backdrop-blur-sm flex items-center justify-center'>
          <div className='w-16 h-16 bg-white border relative rounded-xl flex items-center justify-center'>
            <BiMailSend fontSize={22} className='text-primary' />
            <AiOutlineLoading3Quarters className='text-5xl animate-spin text-primary absolute' />
          </div>
        </div>
      )}
      <div className='min-h-[100vh] lg:flex'>
        <div className='w-full relative lg:w-[62%]'>
          <img
            className='w-full h-full object-cover'
            src={`${process.env.REACT_APP_IMAGE}/service/${packageSelected?.image}`}
            alt='service'
          />
          <div className='absolute left-0 right-0 top-0 bottom-0 p-4 md:p-16 text-white'>
            <h1 className='text-[32px] md:text-[46px] lg:text-[56px]'>
              Services
            </h1>
            <div className='flex gap-10'>
              {packageList?.map(item => (
                <button
                  key={item.id}
                  onClick={() => handleChangeService(item)}
                  className={`
                  w-24 text-start
                  text-[14px] md:text-[16px]
                  ${
                    item.title === activeBtn
                      ? 'text-white relative activeMenuService'
                      : 'text-[rgba(255,255,255,0.5)]'
                  }`}
                >
                  {item.title}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className='flex-1 bg-white py-[12px] px-[16px] lg:py-[124px] lg:px-[40px] xl:py-[120px] xl:px-[90px]'>
          <h2 className='text-[#2E7462] text-[35px] font-[500] mb-[32px]'>
            Inform us what <br /> you need!
          </h2>
          <div>
            <InputCustom
              onChange={e => setName(e.target.value)}
              value={name}
              placeholder='Name'
              type='text'
              name='name'
              id='name'
            />
            <div className='mb-[20px]' />
            <div className='grid gap-4 md:grid-cols-2'>
              <InputCustom
                onChange={e => setEmail(e.target.value)}
                icon={<BiEnvelope />}
                placeholder='Email'
                type='email'
                name='email'
                id='email'
                value={email}
              />
              <InputCustom
                icon={<MdOutlineCall />}
                onChange={e => setPhone(e.target.value)}
                placeholder='Phone Number'
                type='number'
                name='phone'
                id='phone'
                value={phone}
              />
            </div>
            <div className='mb-[20px]' />
            <InputCustom
              icon={<BiMap />}
              placeholder='Address'
              onChange={e => setAddress(e.target.value)}
              type='text'
              name='address'
              id='address'
              value={address}
            />
            <div className='mb-[20px]' />
            <InputCustom
              placeholder='Saya tertarik untuk order servis Videowall...'
              type='text'
              name='message'
              id='message'
              value={message}
              isLongText
              onChange={e => setMessage(e.target.value)}
            />
            <div className='mb-[20px]' />
            <div className='flex text-[#7C7C7C] items-start gap-2'>
              <input
                type='checkbox'
                id='agree'
                className='mt-1 cursor-pointer'
              />
              <label
                htmlFor='agree'
                className='flex-1 text-[12px] leading-[20px] cursor-pointer'
              >
                Dengan mengirimkan pesan ini, Anda setuju dengan penjelasan
                tentang informasi yang dikumpulkan. Kami akan membalas pesan
                Anda sesegera mungkin melalui email atau WhatsApp.
              </label>
            </div>
            <div className='mb-[20px]' />
            <div className='flex justify-end'>
              <button
                onClick={handleSubmit}
                className='w-[120px] shadow-lg h-[40px] bg-[#FFBF1A] rounded-[5px] text-[#2E7462] font-semibold'
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceList
